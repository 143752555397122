import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Your trusted partner for end-to-end automation solutions</h2>
                    <div className="bar"></div>
                    <p>Our proven track record and expertise make us the ideal partner for businesses looking to achieve their automation goals.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>10+</h3>
                            <p>RPA Tool Expertise</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>25+</h3>
                            <p>Projects Delivered</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>94%</h3>
                            <p>Reduction in Human Error</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>100+</h3>
                            <p>People Trained</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>Ready to take your business to the next level?</h3>
                    <p>Schedule a free consultation now to learn how our expert team can help you achieve your goals.</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contact Us
                    </Link>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;